* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
}

.tb {
    width: 1200px;
    margin: 0 auto;
    height: 30px;
}

.btn-back {
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
    border-radius: 8px;
    border-style: none;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-weight: 500;
    height: 2rem;
    width: 4rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.btn-back:hover {
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    transition-duration: .1s;
}

@media (min-width: 768px) {
    .btn-back {
        padding: 0 2.6rem;
    }
}

.item {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto;
    border-bottom: 1px solid #ccc;
    background-color: #455EB5;
}

.item2 {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto;
    border-bottom: 1px solid #ccc;
    background-color: #153197;
}

.item:hover {
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    transition-duration: .1s;
}

.item .icon {   
    color: #e3a126;
    grid-column: 1/2;
    margin-right: 15px;
    margin-bottom: 10px;
    margin: 5px 0 5px 0;
}

.item2 .icon {
    grid-column: 1/2;
    margin-right: 15px;
    margin-bottom: 10px;
    margin: 5px 0 5px 0;
}

.item .name, .item2 .name{
    grid-column: 2/3;
    margin: 5px 0 5px 0;
}

.btn-item {
    background-color: #455EB5;
    border-style: none;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 500;
    height: 30px;
    width: 1150px;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.btn-item a {
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
}

.item a, .item2 a, .item button{
    color: #000;
    font-weight: bold;
    text-align: center;
}